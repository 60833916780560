import React from 'react'
import {BsLinkedin} from 'react-icons/bs'

function HeaderSocials() {
  return (
    <div className='header__socials'>
      <a href="https://linkedin.com/in/sinem-bolatkale-delikaya-1aa09366" target="_blank"><BsLinkedin/></a>
    </div>
  )
}

export default HeaderSocials
