import React from 'react'
import './about.css'    
import ME from '../../assets/Me2023.jpg'
import {FaAward} from 'react-icons/fa'
import {FaHandsHelping} from 'react-icons/fa'
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'

const About = () => {
  return (
    <div>
<section id='about'> 
<div className="container about">
  <h1>Over mij</h1>
  <div className="container about__container">


    <div className="about__me">
      <div className="about__me-image">
        <img src={ME} alt="about image" />

      </div>
    </div>
    <div className="about__content">
      
      <h5>
      Met jarenlange ervaring in de GGZ, heb ik besloten om nu écht dicht bij jou te staan. 
      Ik ben er voor jou en sta klaar om op een warme en laagdrempelige manier samen te werken aan jouw persoonlijke groei.
       Laat me je wat meer vertellen over mezelf: ik ben hartelijk en enthousiast, en altijd nieuwsgierig naar jouw verhaal. 
       Door mijn transparantie en directheid creëer ik een veilige en open omgeving waarin jij je op je gemak voelt. 
       Humor gebruik ik wanneer mogelijk om de sfeer te verlichten en de sessies aangenaam te maken.
       Samen gaan we op zoek naar de beste manier om jou te ondersteunen in je persoonlijke groei en ontwikkeling. 
       Wil je meer informatie of ben je klaar voor de volgende stap? Neem dan gerust contact met me op. 
       Ik kijk er naar uit om je te ontmoeten en samen aan de slag te gaan.
    </h5>
    <h2>Ervaring en opleiding</h2>
    <h5>Als psycholoog NIP beschik ik over een universitair diploma in de psychologie en ben ik aangesloten bij de NIP beroepsvereniging. 
    Daarnaast heb ik uitgebreide ervaring als psycholoog en ben ik opgeleid in verschillende erkende behandelmethodes, 
    waaronder EMDR, CGT en Schematherapie. Mijn expertise ligt op het gebied van angst, depressie en trauma.
    Echter, ook voor andere klachten zoals stress, burn-out, piekeren, negatief zelfbeeld, verlies en rouwverwerking kan je bij mij terecht. 
    Ik bied professionele en deskundige hulpverlening op maat, waarbij ik samen met jou kijk naar de meest geschikte behandeling.
    </h5>

    
    </div>
    
  </div>
  </div>
  </section>
  </div>
  )
}

export default About
