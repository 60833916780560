import React from 'react'
import './behandelingen.css'

const Behandelingen = () => {
  return (
    // <header>
<div>
    <section id='behandelingen'>
    <div className="container behandelingen__container"> 
    
      <h1>Behandelingen</h1>
      <h5>
      Onze praktijk biedt professionele behandeling aan volwassenen vanaf 18 jaar in de generalistische basis GGZ. 
      Deze vorm van eerstelijnspsychologie is bedoeld voor cliënten met lichte tot matige psychische klachten en aandoeningen, zoals: 
    <ol>
      <li>{'\u2022'} Burn-out klachten, </li>
      <li>{'\u2022'} Angststoornissen,</li>
      <li>{'\u2022'} Depressie, </li>
      <li>{'\u2022'} Negatief zelfbeeld,</li>
      <li>{'\u2022'} Problemen met assertiviteit of sociale vaardigheden,</li>
      <li>{'\u2022'} Verlies en rouwverwerking,</li>
      <li>{'\u2022'} Verwerkingsproblemen bij traumatische gebeurtenissen.</li>

    </ol>
    </h5>
    </div>
    </section>
    </div>
  )
}

export default Behandelingen
