import React from 'react'
import './tarieven.css'

const Tarieven = () => {
  return (
    <div >
    <section id='tarieven'>
    <div className="container tarieven__container"> 
    
      <h1>Kosten</h1>
      <h5>
      Bij Psycentraal heb je de keuze uit twee behandeltrajecten: vergoede zorg of niet-vergoede zorg.
      </h5>

      <h5>
        <br />
        Vergoede zorg houdt in dat de behandeling volledig vergoed wordt. 
        Indien de basisverzekering niet de volledige behandeling dekt, zal Caredate de rest vergoeden. 
        Deze regeling geldt voor 2023 en wordt voortgezet in 2024, mits de polisvoorwaarden ongewijzigd blijven. 
        Indien de verzekeraar anders aangeeft, kan er contact opgenomen worden met Caredate op 0850653165 of via info@caredate.nl.
         Let op, het eigen risico van €385 per jaar is niet inbegrepen en komt voor eigen rekening.
</h5>

<h5>
<br />
Als alternatief kun je kiezen voor niet-vergoede zorg, waarbij de kosten voor eigen rekening zijn. 
Een individuele sessie kost €105 (excl. BTW). Er zijn diverse redenen om voor niet-vergoede zorg te kiezen, 
zoals het feit dat sommige behandelingen niet vergoed worden door de zorgverzekering, zoals burn-out, 
relatietherapie en rouwverwerking. Deze behandelingen dienen zelf betaald te worden. 
Daarnaast kunnen kosten voor werkgerelateerde problematiek zoals stress of burn-out vaak gedeclareerd worden bij de werkgever, 
mits dit voorafgaand aan de behandeling is afgestemd.
</h5>

<h5> 
<br />
Indien er een afspraak is gemaakt en er onverwachts verhinderdheid optreedt, 
dan verzoek ik u vriendelijk om dit ten minste 24 uur van tevoren aan mij te laten weten. 
Op deze wijze kan ik nog een andere persoon inplannen. Indien er geen tijdige annulering plaatsvindt, 
zal ik genoodzaakt zijn om een bedrag van 50 euro als no-show kosten in rekening te brengen.
</h5>
{/* </h5>

      </h5> */}
      {/* <h3>Tarief</h3>
          <h5>
          Wij hanteren de tarieven zoals vastgesteld door de Nederlandse Zorgautoriteit (NZa).
          Een individuele intake of een vervolggesprek duurt 45 minuten en kost 105 euro (excl. BTW). 
          De duur van de behandeling is afhankelijk van jouw klachten en vragen. Gemiddeld kan je denken aan 5 tot 12 gesprekken.
          </h5>
      <h3>Betaling en afzeggen
      </h3>
          <h5>Betalen doe je contant na afloop van het gesprek of middels een factuur die ik je opstuur. 
              Hebben we afgesproken en kun je onverhoopt niet komen, laat dat dan 24 uur van tevoren weten, dan kan ik nog iemand anders inplannen. 
              Als er niet op tijd wordt afgemeld ben ik genoodzaakt 50 euro no show kosten in rekening te brengen.
          </h5> */}
      {/* <h3>Vergoedingen
      </h3>
          <h5>Er zijn meerdere manieren om je afspraken bij mij vergoed te krijgen: via je werkgever of in sommige gevallen via je aanvullende zorgverzekering. 
            Vraag bij jouw verzekering even na hoe het zit met een vergoeding. 
              
            </h5> */}
      {/* <h3> 
        Wat is het voordeel van een NIP psycholoog?
      </h3>
            <h5>
            Het kiezen voor een psycholoog zonder BIG registratie heeft een aantal voordelen. Ik heb ze voor je op een rijtje gezet:
                <ol >
                  <li>{'\u2022'} Je hebt zelf de keuzevrijheid om een psycholoog te kiezen waar je een klik mee hebt en waar jij je goed bij voelt. </li>
                  <li>{'\u2022'} Geen lange wachttijden.</li>
                  <li>{'\u2022'} Meestal een vergoeding vanuit je aanvullende verzekering en geen eigen risico. </li>
                  <li>{'\u2022'} Meestal een vergoeding vanuit je aanvullende verzekering en geen eigen risico.</li>
                  <li>{'\u2022'} Je hebt geen verwijzing van de huisarts nodig.</li>
                  <li>{'\u2022'} Er wordt niets gemeld bij de zorgverzekeraar en je privacy blijft gewaarborgd.</li>
                  <li>{'\u2022'} Een persoonlijke benadering. Een kleine praktijk besteedt meer aandacht aan zijn cliënten waardoor jij je prettiger zal voelen.</li>
                </ol>
            </h5> */}
    </div>
    </section>
    </div>
  )
}

export default Tarieven
