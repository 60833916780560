import React from 'react'
import './contact.css'    
import {MdOutlineEmail} from 'react-icons/md'
import {BsPinMap} from 'react-icons/bs'
import {BsWhatsapp} from 'react-icons/bs'
import {useRef} from 'react';
import emailjs from '@emailjs/browser'

const Contact = () => {
  const form = useRef();
 
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_gz5u5g2', 'template_2sxzajh', form.current, 'MaJf6jOZM0JyzNJyp')
      e.target.reset()
      }; 

  return (
    <div>
    <section id='contact'>
      <h1>Contact</h1>
      <div className="container contact__container">
        <div className="contact__options">
        
        <article className="contact__option">
          <MdOutlineEmail className='contact__option-icon'/>
          <h4>Email</h4>
          {/* <h5>info@psycentraal.nl</h5> */}
          <h5><a href="mailto:info@psycentraal.nl" target="_blank">info@psycentraal.nl</a></h5>
        </article>
        
        <article className="contact__option">
          <BsPinMap className='contact__option-icon'/>
          <h4>Adres</h4>
          <h5>Geestbrugkade 32, 2281 CX Rijswijk</h5>
          <h5>KvK-nummer 89092732</h5>
          
          <a href="https://goo.gl/maps/KPXygXZKGRSNkYe39" target="_blank">Directions</a>
        </article>

        <article className="contact__option">
          <BsWhatsapp className='contact__option-icon-wa'/>
          <h4>WhatsApp</h4>
          <h5>0633054424</h5>
          <a href="https://wa.me/31633054424" target="_blank">Stuur een bericht</a>
        </article>

        {/* end of contact options */}
        </div>
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Voorname' required />
            {/* <input type="text" name='initials' placeholder='Initialen' required /> */}
            <input type="text" name='surname' placeholder='Achternaam' required />
            
            {/* <input type="text" name='street' placeholder='Straat' required /> */}
            {/* <input type="text" name='housenumber' placeholder='Huisnummer' required /> */}

            {/* <input type="postcode" name='postcode' placeholder='Postcode' required /> */}
            {/* <input type="text" name='place' placeholder='Woonplaats' required /> */}

            <input type="email" name='email' placeholder='E-mail adres' required />
            <input type="Phonenumber" name='phonenumber' placeholder='Mobiel nummer' required />
            <textarea name="message" rows="7" placeholder='Ik wil me aanmelden voor behandeling voor de volgende klachten / probleemgebieden' required></textarea>
            <button type='submit' className='btn btn-primary'>Maak een afspraak</button>
          </form>
        </div>
        <div>
          
        </div>
</section>  
</div>
)
}

export default Contact
