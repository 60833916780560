import React from 'react'
import './header.css'
import CTA from './CTA'
import nip from '../../assets/nip_logo.png'
import vgct from '../../assets/vgct_logo.png'

import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    // <header>
    <div>
      <div className="container header__company"> 
      <h1> PsyCentraal </h1>
      </div>

      <div className="container header__container"> 

      <h5>
      Wanneer je worstelt met mentale klachten, kan het vinden van de juiste psycholoog een lastige zoektocht zijn. Maar maak je geen zorgen, 
      je staat er niet alleen voor. Laten we elkaar ontmoeten voor een kennismakingsgesprek waarin we samen aan de slag gaan. 
      Ik wil dat jij centraal staat in de behandeling. Vertel me wie je bent, wat je dwarszit en waar je kracht ligt. 
      Samen gaan we op zoek naar oplossingen die voor jou werken. Voel je dat het tijd is om de volgende stap te zetten naar 
      jouw herstel? Of wil je eerst nog wat meer informatie? Aarzel niet en kijk gerust rond op mijn website of neem direct contact met me op. 
      Laten we samen jouw pad naar een betere mentale gezondheid bewandelen.



 
      </h5>
      <div className='img_row'>
        <div className='img_col'>
          <img src={nip} alt="nip" />
        </div>
        <div className='img_col'>
          <img src={vgct} alt="vgct" />
        </div>
      </div> 

    
      <CTA />
      {/* <HeaderSocials/> */}
      
      
       <a href="#contact" className='scroll__down'>Scroll Down</a>
       
      </div>
      </div>
      
  )
}

export default Header
